import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		user: {
			isUserOnline: false,
			name: '',
			avatar: null,
			role: null,
			phone: null,
			payment: null,
			pesel: null,
			email: null,
			accountNumber: null,
			langs: null,
			authToken: null,
			id: null,
			type: null,
		},
		singleTask: {
			deadline: null,
			signs: null,
			status: null,
		},
		tasks: {
			isDone: true,
			inProgress: false,
			toCheck: false,
			noStatus: false
		},
		filters: {
			taskSearchAdmin: '',
			taskSearchCopy: '',
			clientsSearch: '',
			employeeSearch: '',
			apprenticeSearch: '',
			switchAccepted: true,
			switchChecked: true,
			switchSend: true,
			switchReject: true,
			switchEnd: true,

		}
	},
	mutations: {
		updateUser(state, payload, authToken) {
			const {
				name,
				avatar,
				role,
				phone,
				payment,
				pesel,
				email,
				accountNumber,
				langs,
				_id,
				type
			} = payload
			state.user.name = name;
			state.user.avatar = avatar;
			state.user.role = role;
			state.user.phone = phone;
			state.user.payment = payment;
			state.user.pesel = pesel;
			state.user.email = email;
			state.user.accountNumber = accountNumber;
			state.user.langs = langs;
			state.user.id = _id;
			state.user.type = type;
			state.user.authToken = authToken;
		},
		updateIsUserOnline(state, payload) {
			state.user.isUserOnline = payload;
		},
		updateUserName(state, payload) {
			state.user.name = payload;
		},
		updateUserRole(state, payload) {
			state.user.role = payload;
		},
		removeUser(state) {
			state.user.name = null;
			state.user.avatar = null;
			state.user.userID = null;
			state.user.role = null;
			state.user.phone = null;
			state.user.payment = null;
			state.user.pesel = null;
			state.user.email = null; null
			state.user.accountNumber = null;
			state.user.langs = null;
			state.user.id = null;
			state.user.authToken = null;
		},
		removeUserRole(state, payload) {
			state.user.role = payload;
		},
		updateTasksDone(state, payload) {
			state.tasks.isDone = payload;
		},
		updateTasksInProgress(state, payload) {
			state.tasks.inProgress = payload;
		},
		updateTasksToCheck(state, payload) {
			state.tasks.toCheck = payload;
		},
		updateTasksNoStatus(state, payload) {
			state.tasks.noStatus = payload;
		},
		updateFilterTaskSearchAdmin(state, payload){
			state.filters.taskSearchAdmin = payload;
		},
		updateFilterTaskSearchCopy(state, payload){
			state.filters.taskSearchCopy = payload;
		},
		updateFilterClientsSearch(state, payload){
			state.filters.clientsSearch = payload;
		},
		updateFilterEmployeeSearch(state, payload){
			state.filters.employeeSearch = payload;
		},
		updateFilterApprenticeSearch(state, payload){
			state.filters.apprenticeSearch = payload;
		},
		updateFilterSwitchAccepted(state, payload){
			state.filters.switchAccepted = payload;
		},
		updateFilterSwitchSend(state, payload){
			state.filters.switchSend = payload;
		},
		updateFilterSwitchReject(state, payload){
			state.filters.switchReject = payload;
		},
		updateFilterSwitchChecked(state, payload){
			state.filters.switchChecked = payload;
		},
		updateFilterSwitchEnd(state, payload){
			state.filters.switchEnd = payload;
		},
		
	},
	getters: {
		getTaskSearchAdmin(state) {
			return state.filters.taskSearchAdmin;
		},
		getTaskSearchCopy(state) {
			return state.filters.taskSearchCopy;
		},
		getClientsSearch(state) {
			return state.filters.clientsSearch;
		},
		getSwitchChecked(state) {
			return state.filters.switchChecked;
		},
		getEmployeeSearch(state) {
			return state.filters.employeeSearch;
		},
		getApprenticeSearch(state) {
			return state.filters.apprenticeSearch;
		},
		getSwitchAccepted(state) {
			return state.filters.switchAccepted;
		},
		getSwitchSend(state) {
			return state.filters.switchSend;
		},
		getSwitchReject(state) {
			return state.filters.switchReject;
		},
		getSwitchEnd(state) {
			return state.filters.switchEnd;
		},
		getUser(state) {
			return state.user
		},
		getClients(state) {
			return state.clients;
		},
		getUserID(state) {
			return state.user.id;
		},
		getUserMail(state) {
			return state.user.email;
		},
		getUserName(state) {
			return state.user.name;
		},
		getUserAvatar(state) {
			return state.user.avatar;
		},
		getUserRole(state) {
			return state.user.role;
		},
		getTasksDone(state) {
			return state.tasks.isDone;
		},
		getTasksInProgress(state) {
			return state.tasks.inProgress;
		},
		getTasksToCheck(state) {
			return state.tasks.toCheck;
		},
		getTasksNoStatus(state) {
			return state.tasks.noStatus;
		}
	},
	actions: {
	},
	modules: {
	}
})
