import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import DatetimePicker from 'vuetify-datetime-picker'
import VueClipboard from 'vue-clipboard2'
var VueScrollTo = require('vue-scrollto');
 
Vue.config.productionTip = false

//global elements
Vue.prototype.url = process.env.VUE_APP_API;
Vue.prototype.eter = process.env.VUE_APP_ETER;
Vue.prototype.odrzucone = process.env.VUE_APP_ODRZUCONE;
Vue.prototype.$axios = axios;

Vue.use(VueScrollTo, {
	duration: 500,
	easing: "ease",
	offset: -80,
})
Vue.use(Vuelidate)
Vue.use(DatetimePicker)
Vue.use(VueClipboard)


new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
