export const apprenticeRoutes = [
	{
		path: '/praktykant/my-account',
		name: 'MyAccountApprentice',
		component: () => import('../views/MyAccount.vue'),
		meta: {
			role: 'praktykant'
		}
	},
    {
		path: '/praktykant/documents',
		name: 'DocumentsApprentice',
		component: () => import('../views/praktykant/Documents.vue'),
		meta: {
			role: 'praktykant'
		}
	},

	{
		path: '/praktykant/',
		name: 'HomeApprentice',
		component: () => import('../views/praktykant/'),
		meta: {
			role: 'praktykant'
		}
	},
	{
		path: '/praktykant/:id/task/:idtask',
		name: 'SingleTask',
		component: () => import('../views/SingleApprenticeTask.vue'),
		meta: {
			role: 'praktykant'
		}
	},
]