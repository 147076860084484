<template>
    <div>
        <nav>

            <v-app-bar  flat app>
                <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
                <v-toolbar-title class="grey--text">
                    <span class="font-weight-light">Content</span>
                    <span>Writer</span>
                </v-toolbar-title>

                <!-- <v-badge class='ml-auto mr-10 mt-5' :content='3' offset-x='9' offset-y='9'>
                    <v-icon>mdi-bell</v-icon>
                </v-badge>-->

            </v-app-bar>

            <v-navigation-drawer v-model="drawer" app dark>

                <v-container>
                    <v-row  align="center"  justify="center">
                        <v-col class=" pa-0 mx-n7">
                            <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25.3821 0C11.3627 0 0 11.4149 0 25.4989C0 39.5828 11.3627 51 25.3821 51C39.4016 51 50.7665 39.5828 50.7665 25.4989C50.7665 11.4149 39.4016 0 25.3821 0Z" fill="white"/>
                            <path d="M33.4366 24.8289C33.388 24.9065 32.2948 26.6282 28.2798 26.3487C28.2025 26.3464 28.123 26.342 28.123 26.342C28.1517 26.3997 28.2003 26.4463 28.2334 26.4662C28.6729 26.7169 29.5541 27.1474 30.5369 27.2206C30.6031 27.225 30.8726 27.2472 30.8726 27.2472C30.8173 27.3271 30.6716 27.4646 30.6186 27.5046C27.6217 29.6966 22.8403 30.3333 21.4445 30.4776C21.4246 30.4798 21.4048 30.4909 21.3937 30.5086L20.2961 32.1083L16.9613 37.1601C17.7497 36.5278 18.7303 36.6321 18.7303 36.6321C19.1322 34.1516 20.3292 32.8892 21.1 32.0994C21.4732 31.7178 22.1203 31.3339 22.6437 31.2385C22.5466 31.2896 19.3266 32.5187 19.3266 37.3531C19.3266 37.3531 16.7714 36.4391 14.2846 40.5546C14.2846 40.5546 15.6274 35.2787 16.1177 34.8572C16.6057 34.4356 18.2952 33.3507 20.528 30.0649C22.1314 27.2006 24.7418 23.247 28.1914 20.3894C28.2754 20.3117 30.157 18.4259 31.3275 17.2322C31.3695 17.1901 31.4136 17.0525 31.4136 17.0525C31.3385 17.0703 31.2701 17.1058 31.2347 17.1346C30.2718 17.9422 28.7193 19.1758 27.5863 19.9745C27.0651 20.3428 22.4472 23.6929 20.3911 28.1324C20.3337 28.2567 20.1526 28.4186 20.1526 28.4186C20.1526 28.4186 20.0797 28.148 20.1305 27.8396C20.3182 26.6903 20.7864 24.6026 22.0408 22.1865C23.5713 19.2357 25.7312 17.0015 28.4587 15.5461C35.464 11.8077 35.6694 10.6406 35.6716 10.5208V10.4987L35.6893 10.4809L35.7378 10.4454L35.7577 10.5031C35.7644 10.5231 36.4357 12.6086 36.4843 15.4019C36.5241 17.9799 36.0338 21.7095 33.4366 24.8289Z" fill="#17223A"/>
                            </svg>
                        </v-col>
                        <v-col>
                            <v-row>
                                <span class="white--text text-left">{{name}}</span>
                            </v-row>
                            <v-row>
                                <span class="white--text text-capitalize">{{role}}  
                                </span>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
                <v-list dense nav v-if="role == 'administrator'">
                    <v-divider v-if="name"></v-divider>
                    <v-list-item
                        v-for="item in itemsAdmin"
                        :key="item.title"
                        link
                        :to="item.route"
                        exact
                    >

                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>

                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item link @click="logout">

                        <v-list-item-icon>
                            <v-icon>mdi-logout-variant</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Wyloguj</v-list-item-title>
                        </v-list-item-content>

                    </v-list-item>

                </v-list>

                <v-list dense nav  v-if="role == 'copywriter'">
                    <v-divider v-if="name"></v-divider>
                    <v-list-item
                        v-for="item in itemsCopywriter"
                        :key="item.title"
                        link
                        :to="item.route"
                        exact
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item link @click="logout">
                        <v-list-item-icon>
                            <v-icon>mdi-logout-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Wyloguj</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-list dense nav  v-if="role == 'moderator'">
                    <v-divider v-if="name"></v-divider>
                    <v-list-item
                        v-for="item in itemsModerator "
                        :key="item.title"
                        link
                        :to="item.route"
                        exact
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item link @click="logout">
                        <v-list-item-icon>
                            <v-icon>mdi-logout-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Wyloguj</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
				
                <v-list dense nav  v-if="role == 'korektor'">
                    <v-divider v-if="name"></v-divider>
                    <v-list-item
                        v-for="item in itemsKorektorFilter "
                        :key="item.title"
                        link
                        :to="item.route"
                        exact
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item link @click="logout">
                        <v-list-item-icon>
                            <v-icon>mdi-logout-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Wyloguj</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-list dense nav  v-if="role == 'praktykant'">
                    <v-divider v-if="name"></v-divider>
                    <v-list-item
                        v-for="item in itemsApprentice "
                        :key="item.title"
                        link
                        :to="item.route"
                        exact
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item link @click="logout">
                        <v-list-item-icon>
                            <v-icon>mdi-logout-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Wyloguj</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-list-item v-if="!isEmpty" link @click="logout">

                <v-list-item-icon>
                    <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Wyloguj</v-list-item-title>
                </v-list-item-content>

            </v-list-item>
            </v-navigation-drawer>
            
        </nav>
    </div>
</template>

<script>

export default {
    name: 'Navbar',
    data(){
        return{
            drawer: false,
            tutor: '',
            itemsCopywriter: [
                { title: 'Zadania', icon: 'mdi-format-list-bulleted', route: '/copywriter/'+ new Date().toISOString().substr(0, 7) + '' ,exact: 'exact' },
                { title: 'Eter', icon: 'mdi-file-search', route: '/copywriter/take-task' },
                { title: 'Moje konto', icon: 'mdi-account', route: '/copywriter/my-account'},
                { title: 'Płatności', icon: 'mdi-cash', route: '/copywriter/payments/'+ new Date().toISOString().substr(0, 7) +'/'+localStorage.getItem('id')+'' },
                { title: 'Dokumenty', icon: 'mdi-file-document-outline', route: '/copywriter/documents' },
            ],
            itemsKorektor: [
                { title: 'Zadania', icon: 'mdi-format-list-bulleted', route: '/korektor/' + new Date().toISOString().substr(0, 7) + '',exact: 'exact' },
				{ title: 'Moje konto', icon: 'mdi-account', route: '/korektor/my-account'},
                { title: 'Praktykanci', icon: 'mdi-account', route: '/korektor/apprentice' },
                { title: 'Płatności', icon: 'mdi-cash', route: '/korektor/payments/'+ new Date().toISOString().substr(0, 7) +'/'+localStorage.getItem('id')+'' },
                { title: 'Dokumenty', icon: 'mdi-file-document-outline', route: '/korektor/documents' },
            ],
            itemsAdmin: [
                { title: 'Zadania', icon: 'mdi-format-list-bulleted', route: '/administrator/'+ new Date().toISOString().substr(0, 7) + '', exact: 'exact'},
                { title: 'Zadania dla praktykantów', icon: 'mdi-format-list-numbered', route: '/administrator/apprentice-tasks', exact: 'exact'},
                { title: 'Klienci', icon: 'mdi-account-multiple', route: '/administrator/clients' },
                { title: 'Pracownicy', icon: 'mdi-account-cash', route: '/administrator/employees' },
                { title: 'Praktykanci', icon: 'mdi-account', route: '/administrator/apprentice' },
                { title: 'Płatności', icon: 'mdi-cash', route: '/administrator/payments/'+ new Date().toISOString().substr(0, 7) +'' },
                { title: 'Dokumenty', icon: 'mdi-file-document-outline', route: '/administrator/documents' },
            ],
            itemsModerator: [
                { title: 'Zadania', icon: 'mdi-format-list-bulleted', route: '/moderator/' + new Date().toISOString().substr(0, 7) + '', exact: 'exact' },
                { title: 'Klienci', icon: 'mdi-account-multiple', route: '/moderator/clients' },
                { title: 'Zadania dla praktykantów', icon: 'mdi-format-list-numbered', route: '/moderator/apprentice-tasks', exact: 'exact'},
                { title: 'Pracownicy', icon: 'mdi-account-cash', route: '/moderator/employees' },
                { title: 'Praktykanci', icon: 'mdi-account', route: '/moderator/apprentice' },
                { title: 'Płatności', icon: 'mdi-cash', route: '/moderator/payments/'+ new Date().toISOString().substr(0, 7) +'' },
                { title: 'Dokumenty', icon: 'mdi-file-document-outline', route: '/moderator/documents' },
            ],
            itemsApprentice: [
                { title: 'Zadania', icon: 'mdi-format-list-bulleted', route: '/praktykant/', exact: 'exact' },
                { title: 'Moje konto', icon: 'mdi-account', route: '/praktykant/my-account' },

            ],
        }
    },
    methods: {
        logout(){
            localStorage.clear();
            this.$router.push('/')
            this.$store.commit('removeUser')
			
        },
        getTutor(){
            this.$axios.get(`${this.url}api/users/practice-tutor`)
            .then((el)=>{
                this.tutor = el.data.email
            }).catch(()=>{
                this.isLoaded = false;
            })
        },

    },
    created(){
        this.getTutor();
    },
    computed: {
        itemsKorektorFilter(){
        if(this.tutor === this.email){
            return [
                { title: 'Zadania', icon: 'mdi-format-list-bulleted', route: '/korektor/' + new Date().toISOString().substr(0, 7) + '',exact: 'exact' },
                { title: 'Moje konto', icon: 'mdi-account', route: '/korektor/my-account'},
                { title: 'Praktykanci', icon: 'mdi-account', route: '/korektor/apprentice' },
                { title: 'Płatności', icon: 'mdi-cash', route: '/korektor/payments/'+ new Date().toISOString().substr(0, 7) +'/'+localStorage.getItem('id')+'' },
                { title: 'Dokumenty', icon: 'mdi-file-document-outline', route: '/korektor/documents' },
            ];
        }else {
            return  [
                { title: 'Zadania', icon: 'mdi-format-list-bulleted', route: '/korektor/' + new Date().toISOString().substr(0, 7) + '',exact: 'exact' },
                { title: 'Moje konto', icon: 'mdi-account', route: '/korektor/my-account'},
                { title: 'Płatności', icon: 'mdi-cash', route: '/korektor/payments/'+ new Date().toISOString().substr(0, 7) +'/'+localStorage.getItem('id')+'' },
                { title: 'Dokumenty', icon: 'mdi-file-document-outline', route: '/korektor/documents' },
            ];
        }
        },
        isEmpty(){
            return this.$store.getters.getUserID && this.$store.getters.getUserRole && this.$store.getters.getUserName
        },
        name() {
            return this.$store.getters.getUserName
        },
        email() {
            return this.$store.getters.getUserMail
        },
        role() {
            return this.$store.getters.getUserRole
        },
        id() {
            return this.$store.getters.getUserID
        }
    },
}
</script>
