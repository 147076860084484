export const adminRoutes = [ 
	{
		path: '/administrator/clients',
		name: 'ClientsAdmin',
		component: () => import('../views/administrator/Clients.vue'),
		meta: {
			role: 'administrator'
		}
	},
	{
		path: '/administrator/documents',
		name: 'DocumentAdministrator',
		component: () => import('../views/administrator/Documents.vue'),
		meta: {
			role: 'administrator'
		}
	},
	{
		path: '/administrator/employees',
		name: 'EmployeesAdmin',
		component: () => import('../views/administrator/Employees.vue'),
		meta: {
			role: 'administrator'
		}
	},
	{
		path: '/administrator/apprentice-tasks',
		name: 'ApprenticeTasksAdmin',
		component: () => import('../views/ApprenticeTasks.vue'),
		meta: {
			role: 'administrator'
		}
	},
	{
		path: '/administrator/apprentice',
		name: 'AllApprenticeViewAdmin',
		component: () => import('../views/Apprentice.vue'),
		meta: {
			role: 'administrator'
		}
	},
	{
		path: '/administrator/apprentice/:id',
		name: 'SingleApprenticeAdmin',
		component: () => import('../views/SingleApprentice.vue'),
		meta: {
			role: 'administrator'
		}
	},
	{
		path: '/administrator/apprentice/:id/task/:idtask',
		name: 'SingleApprenticeTaskAdmin',
		component: () => import('../views/SingleApprenticeTask.vue'),
		meta: {
			role: 'administrator'
		}
	},
	{
		path: '/administrator/task/:id',
		name: 'SingleTaskAdmin',
		component: () => import('../views/SingleTask.vue'),
		meta: {
			role: 'administrator'
		}
	},
	{
		path: '/administrator/payments/:month',
		name: 'PaymentsAdmin',
		component: () => import('../views/administrator/PaymentsAdmin.vue'),
		meta: {
			role: 'administrator'
		}
	},
	{
		path: '/administrator/my-account',
		name: 'MyAccountAdmin',
		component: () => import('../views/MyAccount.vue'),
		meta: {
			role: 'administrator'
		}
	},
	// {
	// 	path: '/administrator/',
	// 	name: 'HomeAdmin',
	// 	component: () => import('../views/administrator/'),
	// 	meta: {
	// 		role: 'administrator'
	// 	}
	// },
	{
		path: '/administrator/:month',
		name: 'HomeAdmin',
		component: () => import('../views/administrator/'),
		meta: {
			role: 'administrator'
		}
	},
]