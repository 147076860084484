<template>
    <v-app id="app">
		<Navigation 
		v-if="this.$route.path !== '/' && !this.$route.path.includes('passwd-reset')  && !this.$route.path.includes('new-user')"
		/>
		<v-main>
			<transition name='fade' mode='out-in'>
				<router-view/>
			</transition>
		</v-main>
		<v-overlay :value="!isEmpty" v-if="this.$route.path !== '/' && !this.$route.path.includes('passwd-reset') && !this.$route.path.includes('new-user')">
			<v-btn
				readonly
				@click="logout"
				color="indigo"
				dark
				class="mr-5 mb-4"
			>
				Wyloguj 
			</v-btn>
		</v-overlay>
    </v-app>
</template>

<script>
import Navigation from '@/components/organisms/Navigation';
import axios from 'axios';

export default {
	components: {
		Navigation
	},    
	computed: {
        isEmpty(){
            return this.$store.getters.getUserID && this.$store.getters.getUserRole && this.$store.getters.getUserName
        },
	},
	methods: {
		logout(){
            localStorage.clear();
            this.$router.push('/')
            this.$store.commit('removeUser')
			
        },
	},
	mounted() {
		//after reload page fetch data from server if user was legged
		const userAuth = JSON.parse(localStorage.getItem('user'));
		if(userAuth){
			axios.get(this.url + 'api/login', { headers: { 'x-access-token': userAuth } })
				.then((res)=> {
					this.$store.commit('updateUser', res.data.user, res.data.authToken);
				})
		}
	},
}
</script>

<style lang="scss">

.fade-enter-active {
  transition: opacity 1s;
}

.fade-enter {
  opacity: 0;
}


#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}

</style>
